import inventarioDescargarService from '../../../../services/inventarioDescargarService'
const useInventarioHome = () => {
  const handleDownload = () => {
    const url = inventarioDescargarService()
    window.open(url, '_blanck')
  }
  return {
    handleDownload
  }
}
export default useInventarioHome
