<template>
  <section class="intro-x my-5">
    <div class="w-full flex flex-col sm:flex-row " >
      <div class="w-1/2 my-auto">
        <h1 class="text-lg font-bold">Inventario</h1>
      </div>
      <div v-if="$can('pharmasan.administrativa.compras-administrativas.inventario.access')" class="w-1/2 flex justify-end">
        <router-link
        :to="{name: 'pharmasan.administrativa.compras-administrativas.inventario.carga-masiva'}"
        class="bg-primary-1 py-3 px-2 text-white rounded-md capitalize flex items-center mb-2 mr-1"
        >
          <span class="mr-2">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
            </svg>
          </span>
          <span style="position:relative; top:1px;">Carga masiva </span>
        </router-link>
        <router-link
        :to="{name: 'pharmasan.administrativa.compras-administrativas.inventario.entradas'}"
        class="bg-primary-1 py-3 px-2 text-white rounded-md capitalize flex items-center mb-2 mr-1"
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13,2A2,2 0 0,0 11,4V20A2,2 0 0,0 13,22H22V2H13M20,10V14H13V10H20M20,16V20H13V16H20M20,4V8H13V4H20M9,11H6V8H4V11H1V13H4V16H6V13H9V11Z" />
          </svg>
          <span>Entrada</span>
        </router-link>
        <router-link
        :to="{name:'pharmasan.administrativa.compras-administrativas.inventario.salidas'}"
        class="bg-primary-1 py-3 px-2 text-white rounded-md capitalize flex items-center mb-2 mr-1"
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M4,2H11A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H4A2,2 0 0,1 2,20V4A2,2 0 0,1 4,2M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M17.59,12L15,9.41L16.41,8L19,10.59L21.59,8L23,9.41L20.41,12L23,14.59L21.59,16L19,13.41L16.41,16L15,14.59L17.59,12Z" />
          </svg>
          <span> Salida </span>
        </router-link>
        <button
        class="bg-green-600 py-3 px-2 text-white rounded-md capitalize flex items-center mb-2 mr-1"
        type="button"
        title="Descargar inventario en formato excel"
        @click="handleDownload"
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
          </svg>
          <span> Descargar </span>
        </button>
      </div>
    </div>
    <div class="w-full rounded-md" style="overflow:hidden; overflow-x: auto;" >
      <ListadoArticulos />
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import useInventarioHome from './useInventarioHome'
export default {
  components: {
    ListadoArticulos: defineAsyncComponent(() => import('../listado'))
  },
  setup () {
    const { handleDownload } = useInventarioHome()
    return {
      handleDownload
    }
  }

}
</script>
